import { Divider, ListItem, ListItemButton, ListItemText } from "@mui/material";

import { type Worker } from "../Worker/api/types";
import { useSkillsAssessmentFeatureFlags } from "./api/useSkillsAssessmentFeatureFlags";
import { useSkillsAssessments } from "./api/useSkillsAssessments";

interface Props {
  worker: Worker;
  onClick: () => void;
}
export function SkillsAssessmentAccountMenuItem(props: Props) {
  const { onClick, worker } = props;
  const { userId } = worker;

  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const { data: skillsAssessments, isSuccess: isSuccessSkillsAssessments } = useSkillsAssessments(
    {
      workerId: userId,
      qualificationNames: skillsAssessmentConfig.availableQualificationNames,
    },
    { staleTime: 0 }
  );

  if (!isSuccessSkillsAssessments || skillsAssessments.data.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <ListItem>
        <ListItemButton onClick={onClick}>
          <ListItemText primary="Skills Assessment" />
        </ListItemButton>
      </ListItem>
    </>
  );
}
