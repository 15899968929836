import { isDefined } from "@clipboard-health/util-ts";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { qualificationSchema } from "@src/appV2/Qualifications/types";
import { z } from "zod";

import {
  assessmentEventSchema,
  skillsAssessmentSchema,
  skillsAssessmentStatusSchema,
} from "../types";

export const skillsAssessmentsParamsSchema = z
  .object({
    filter: z.object({
      workerId: z.string(),
      qualificationNames: z.array(z.string()),
    }),
    include: z.string(),
  })
  .optional();
export type SkillsAssessmentsParams = z.infer<typeof skillsAssessmentsParamsSchema>;

export const assessmentQualificationSchema = qualificationSchema.extend({
  attributes: qualificationSchema.shape.attributes.pick({ name: true }),
});
export type AssessmentQualification = z.infer<typeof assessmentQualificationSchema>;

export const skillsAssessmentsResponseSchema = z.object({
  data: z.array(skillsAssessmentSchema),
  included: z.array(
    z.union([skillsAssessmentStatusSchema, assessmentQualificationSchema, assessmentEventSchema])
  ),
});
export type SkillsAssessmentsResponse = z.infer<typeof skillsAssessmentsResponseSchema>;

export const SKILLS_ASSESSMENTS_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/assessments`;

export function getSkillsAssessmentsQueryKey(params?: SkillsAssessmentsParams) {
  return [SKILLS_ASSESSMENTS_URL, ...(isDefined(params) ? [params] : [])];
}

export function useSkillsAssessments(
  params: {
    workerId: string;
    qualificationNames: string[];
  },
  options: UseGetQueryOptions<SkillsAssessmentsResponse> = {}
) {
  const { workerId, qualificationNames } = params;
  const queryParams: SkillsAssessmentsParams = {
    filter: {
      workerId,
      qualificationNames,
    },
    include: "assessment-status,qualification,latestScheduledEvent",
  };
  return useGetQuery({
    url: SKILLS_ASSESSMENTS_URL,
    queryParams,
    requestSchema: skillsAssessmentsParamsSchema,
    responseSchema: skillsAssessmentsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SKILLS_ASSESSMENTS_FAILURE,
    },
    retry: 0,
    staleTime: 1000 * 60 * 1,
    ...options,
  });
}
