import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

import {
  type CreateAssessmentEventParams,
  type CreateAssessmentEventResponse,
  useCreateAssessmentEvent,
} from "./useCreateAssessmentEvent";

export const startAssessmentParamsSchema = z.object({
  workerId: z.string(),
  assessmentId: z.string(),
});
export type StartAssessmentParams = z.infer<typeof startAssessmentParamsSchema>;

export function useStartAssessment(
  options: UseMutationOptions<
    CreateAssessmentEventResponse,
    string,
    CreateAssessmentEventParams
  > = {}
) {
  const mutation = useCreateAssessmentEvent({
    onError: (error, variables) => {
      logEvent(APP_V2_APP_EVENTS.SKILLS_ASSESSMENTS_STARTED_EVENT_FAILURE, { error, variables });
    },
    ...options,
  });

  return {
    ...mutation,
    mutateAsync: async (params: StartAssessmentParams) => {
      const startedParams = { ...params, status: "STARTED" };
      return await mutation.mutateAsync(startedParams);
    },
  };
}
