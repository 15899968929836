// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { useModalState } from "@clipboard-health/ui-react";
import { IonPage } from "@ionic/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, AppBarHeader, PageWithHeader, useToast } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { differenceInMinutes, parseISO } from "date-fns";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { DeprecatedGlobalAppV1Paths } from "../App/paths";
import { logEvent } from "../lib/analytics";
import { useDefinedWorker } from "../Worker/useDefinedWorker";
import { useSkillsAssessmentFeatureFlags } from "./api/useSkillsAssessmentFeatureFlags";
import { type AssessmentQualification, useSkillsAssessments } from "./api/useSkillsAssessments";
import { useStartAssessment } from "./api/useStartAssessment";
import { useSubmitAssessment } from "./api/useSubmitAssessment";
import { ExitRecordingConfirmationDialog } from "./components/ExitRecordingConfirmationDialog";
import { SkillsAssessmentStatus } from "./components/Status/SkillsAssessmentStatus";
import {
  SkillsAssessmentVideoFlow,
  SkillsAssessmentVideoFlowStep,
} from "./components/VideoFlow/VideoFlow";
import {
  type AssessmentEvent,
  AssessmentType,
  type SkillsAssessmentStatus as SkillsAssessmentStatusType,
} from "./types";
import { checkIsWorkerAllowedToTakeAssessment } from "./utils/checkIsWorkerAllowedToTakeAssessment";

export function SkillsAssessmentMyAccountPage() {
  const worker = useDefinedWorker();
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_MY_ACCOUNT_PAGE_VIEWED);

  const { skillsAssessmentConfig, scheduledAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const {
    data: assessments,
    isLoading,
    isSuccess,
    refetch,
  } = useSkillsAssessments({
    workerId: worker.userId,
    qualificationNames: skillsAssessmentConfig.availableQualificationNames,
  });
  const { mutateAsync: submitAssessment } = useSubmitAssessment();
  const { mutateAsync: startAssessment } = useStartAssessment();

  const confirmExitDialogState = useModalState();
  const history = useHistory();

  const { showSuccessToast } = useToast();

  const assessmentQualification = assessments?.included.find(
    (included) => included.type === "qualification"
  ) as AssessmentQualification | undefined;
  const assessmentStatus = assessments?.included.find(
    (included) => included.type === "assessment-status"
  ) as SkillsAssessmentStatusType;
  const assessmentScheduledEvent = assessments?.included.find(
    (included) => included.type === "assessment-event"
  ) as AssessmentEvent | undefined;
  const shouldShowVideoFlow = isSuccess
    ? checkIsWorkerAllowedToTakeAssessment({
        assessmentType: assessmentStatus?.attributes.type,
        assessmentStatus: assessmentStatus?.attributes.status,
        assessmentScheduledFor: assessmentScheduledEvent?.attributes.scheduledFor,
        allowedEarlinessInMinutes: scheduledAssessmentConfig.allowedEarlinessInMinutes,
        retakeAllowedAfter: assessmentStatus?.attributes.retakeAllowedAfter,
      })
    : false;

  const onExit = () => {
    if (shouldShowVideoFlow && !isAssessmentCompleted) {
      confirmExitDialogState.openModal();
    } else {
      history.push(DeprecatedGlobalAppV1Paths.ACCOUNT);
      // refetching to make sure that the data is updated immediately and the worker can't retake the assessment
      void refetch();
    }
  };

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Skills Assessment"
            leftCta={
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="Go Back"
                title="Go Back"
                onClick={onExit}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
            }
          />
        }
      >
        <Stack alignItems="center" width="100%" height="100%" paddingTop={2} paddingBottom={5}>
          {isLoading && <CircularProgress />}
          {isSuccess &&
            assessments.included.length > 0 &&
            (shouldShowVideoFlow ? (
              <>
                <SkillsAssessmentVideoFlow
                  initialStep={
                    assessmentStatus.attributes.type === AssessmentType.ASYNCHRONOUS
                      ? SkillsAssessmentVideoFlowStep.START_FROM_MY_ACCOUNT_ASYNC
                      : SkillsAssessmentVideoFlowStep.START_FROM_MY_ACCOUNT_SCHEDULED
                  }
                  assessment={assessments.data[0]}
                  assessmentQualification={assessmentQualification?.attributes.name}
                  onSkip={onExit}
                  onAssessmentStarted={() => {
                    void startAssessment({
                      assessmentId: assessments.data[0].id,
                      workerId: worker.userId,
                    });
                    logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_STARTED_EVENT, {
                      assessmentId: assessments.data[0].id,
                      workerId: worker.userId,
                      scheduledFor: assessmentScheduledEvent?.attributes.scheduledFor,
                      minutesSinceScheduled: differenceInMinutes(
                        new Date(),
                        parseISO(assessmentScheduledEvent?.attributes.scheduledFor ?? "")
                      ),
                    });
                  }}
                  onAssessmentCompleted={() => {
                    setIsAssessmentCompleted(true);
                    void submitAssessment({
                      assessmentId: assessments.data[0].id,
                      workerId: worker.userId,
                    });
                  }}
                  onFinish={onExit}
                />
                <ExitRecordingConfirmationDialog
                  modalState={confirmExitDialogState}
                  assessment={assessments.data[0]}
                  onConfirm={() => {
                    history.push(DeprecatedGlobalAppV1Paths.ACCOUNT);
                    logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_VIDEO_ASK_EXITED, {
                      assessment: assessments.data[0],
                    });
                    confirmExitDialogState.closeModal();
                  }}
                />
              </>
            ) : (
              <>
                <PullToRefresh onRefresh={refetch} />
                <SkillsAssessmentStatus
                  assessment={assessments.data[0]}
                  assessmentStatus={assessmentStatus}
                  dueDate={assessmentStatus.attributes.dueDate}
                  qualification={assessmentQualification?.attributes.name}
                  assessmentScheduledFor={assessmentScheduledEvent?.attributes.scheduledFor}
                  onSchedule={() => {
                    showSuccessToast("Assessment successfully scheduled!");
                    history.push(DeprecatedGlobalAppV1Paths.ACCOUNT);
                    void refetch();
                  }}
                  onReschedule={() => {
                    showSuccessToast("Assessment successfully rescheduled!");
                    history.push(DeprecatedGlobalAppV1Paths.ACCOUNT);
                    void refetch();
                  }}
                />
              </>
            ))}
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
